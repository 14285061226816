import React, { useCallback, useEffect, useRef, useState } from 'react';

import BannerHero from './BannerHero/BannerHero';
import styled from '@emotion/styled';
import { breakPoints } from 'constant/breakPoints';

const AMOUNT_OF_BANNER = 4;
let timeout: any;

const BannerService = (): JSX.Element => {
  const [step, setStep] = useState(0);
  const isFirstTime = useRef(true);

  const handleSelectStep = useCallback(
    (s: number) => () => {
      setStep(s);
    },
    [],
  );

  useEffect(() => {
    clearTimeout(timeout);

    timeout = setTimeout(
      () => {
        setStep((step) => (step === 3 ? 3 : step + 1));
        isFirstTime.current = false;
      },
      isFirstTime.current ? 12_000 : 6_000,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [step]);

  return (
    <Wrap>
      <BannerHero step={step} />

      <Control>
        <DotList>
          {Array(AMOUNT_OF_BANNER)
            .fill(0)
            .map((_, idx) => (
              <Dot
                key={idx}
                active={idx === step}
                onClick={handleSelectStep(idx)}
              />
            ))}
        </DotList>
      </Control>
    </Wrap>
  );
};

export default BannerService;

const Wrap = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-top: 110px;

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    display: none;
  }

  @media screen and (min-width: ${breakPoints.desktopM}px) {
    padding-top: 20px;
    width: calc(100vw - 20px);
    flex-direction: initial;
    align-items: center;
    justify-content: space-between;
  }

  .hidden {
    display: none;
  }
`;

const Control = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 20;

  @media screen and (max-width: ${breakPoints.desktopM - 1}px) {
    width: 100%;
  }

  @media screen and (min-width: ${breakPoints.desktopM}px) {
    left: 10.27%;
    left: calc((100vw - 1140px) / 2);
    bottom: 8.888888%;
  }
`;

const DotList = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${breakPoints.desktopM - 1}px) {
    justify-content: center;
  }
`;

const Dot = styled.div<{ active: boolean }>`
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;

  ${({ active }) =>
    active &&
    `
    transform: scale(1.6);
    background-color: #ffffff;
  `}
`;
