import { keyframes } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';
import styled from '@emotion/styled';

import Block from './Block';
import Design from './Design';
import Web from './Web';
import Dedicated from './Dedicated';
import { breakPoints } from 'constant/breakPoints';
import { BANNER_CONTENT } from 'constant/appConstant';

const BannerDesign = ({ step }: { step: number }): JSX.Element => {
  const [scale, setScale] = useState(1);

  const content = useMemo(() => {
    const m = new Map();

    BANNER_CONTENT.map((item, idx) =>
      m.set(
        idx,
        <Content key={0} className="banner-content animate-button">
          <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
          <p dangerouslySetInnerHTML={{ __html: item.title }} />
          <Link href={item.link} passHref prefetch={false}>
            <ButtonReadMore aria-label={item.title}>Read more</ButtonReadMore>
          </Link>
        </Content>,
      ),
    );

    return m;
  }, []);

  // Delay
  useEffect(() => {
    setTimeout(() => {
      // Calc
      const sca = (window.innerHeight - 82) / 763;

      setScale(sca > 1 ? 1 : sca);
    }, 3_000);
  }, []);

  return (
    <>
      {content.get(step)}
      <ImageWrap scale={scale}>
        <Design step={step} />
        <Web step={step} />
        <Block step={step} />
        <Dedicated step={step} />
      </ImageWrap>
    </>
  );
};

export default BannerDesign;

const aniContent = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  100%{
    opacity: 1;
    transform: translateX(0);
  }
`;
const aniButton = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100%{
    opacity: 1;
    transform: translateY(0);
  }
`;
const Content = styled.div`
  z-index: 5;

  &.banner-content {
    padding-bottom: 96px;
    text-align: center;

    p {
      transition: all 1s ease;
      line-height: 130%;
    }

    p:first-of-type {
      font-weight: 500;
      font-size: 16px;
      color: #d0cfd4;
      margin-bottom: 16px;
      opacity: 0;
      animation: ${aniButton} 1s ease 0s forwards;
    }

    p:last-of-type {
      font-weight: 600;
      font-size: 32px;
      color: #ffffff;
      font-weight: 600;
      opacity: 0;
      animation: ${aniButton} 1s ease 0.3s forwards;
    }

    a {
      position: absolute;
      bottom: 30.555vh;
      z-index: 20;
    }
  }

  @media screen and (max-width: ${breakPoints.desktopM - 1}px) {
    position: relative;

    &.banner-content {
      padding-left: 15px;
      padding-right: 15px;

      a {
        left: 50%;
        bottom: 30px;
        animation: none;
        transform: translateX(-50%);
      }
    }
  }

  @media screen and (min-width: ${breakPoints.desktopM}px) {
    &.banner-content {
      padding-left: 10.27%;
      padding-left: calc((100vw - 1140px) / 2);
      text-align: left;

      p:first-of-type {
        font-size: 26px;
        max-width: 529px;
        transform: translateX(-300px);
        animation: ${aniContent} 1s ease 0s forwards;
      }

      p:last-of-type {
        transform: translateX(-300px);
        font-size: 56px;
        max-width: 536px;
        animation: ${aniContent} 1s ease 0.3s forwards;
      }

      a {
        bottom: 27vh;
      }
    }
  }

  @media screen and (min-width: ${breakPoints.desktopXM}px) {
    &.banner-content {
      p:first-of-type {
        font-size: 28px;
      }

      p:last-of-type {
        font-size: 58px;
      }

      a {
        bottom: 30.555vh;
      }
    }
  }
`;

const ImageWrap = styled.div<{ scale: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: scale(0.5) translateY(37%);
  z-index: 2;

  // Once image
  & > div {
    position: absolute;
    width: 736px;
    height: 736px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: ${breakPoints.desktopM}px) {
    position: relative;
    height: 100%;
    margin: initial;
    max-height: 736px;
    width: ${({ scale }) => 736 * scale + 'px'};
    flex: 0 0 ${({ scale }) => 736 * scale + 'px'};
    transform: ${({ scale }) =>
      `scale(${scale}) translateX(${(-736 * (1 - scale)) / 2}px)`};

    & > div {
      left: 0;
      transform: initial;
    }
  }

  @media screen and (min-width: ${breakPoints.desktopL}px) {
    margin-right: 7%;
  }
`;
const ButtonReadMore = styled.a`
  margin-top: 40px;
  background: linear-gradient(
    188.34deg,
    #a336d9 13.45%,
    rgba(163, 54, 217, 0.6) 144.25%
  );
  border-radius: 100px;
  width: 179px;
  height: 56px;
  display: block;
  line-height: 56px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  border: 2px solid transparent;

  &:hover {
    border-color: #fff;
    background: transparent;
    color: #fff;
  }
`;
