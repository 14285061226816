import Head from 'next/head';
import type { NextPage } from 'next';
import dynamic from 'next/dynamic';

const CoreValue = dynamic(() => import('components/CoreValue/CoreValue'), {
  ssr: false,
});
const ContactUs = dynamic(() => import('components/ContactUs/ContactUs'));
const Counter = dynamic(() => import('components/Counter/Counter'));
const WhoWeAre = dynamic(() => import('components/WhoWeAre/WhoWeAre'));
const Partners = dynamic(() => import('components/Partners/Partners'));
const MeetTheTeam = dynamic(() => import('components/MeetTheTeam/MeetTheTeam'));
const Life = dynamic(() => import('components/Life'));
const VisionCarousel = dynamic(
  () => import('components/OurMission/VisionCarousel'),
);
const Specialized = dynamic(() => import('components/Specialized/Specialized'));

import BannerService from 'components/Banner/BannerService';
import BannerServiceMobile from 'components/Banner/BannerService.mobile';
import useTheme from 'hooks/useTheme';

const Home: NextPage = () => {
  useTheme({
    light: false,
  });

  return (
    <>
      <Head>
        <title>Spirit Labs: Software Development Company</title>
        <meta
          name="description"
          content="Spirit Labs is a full-stack software development company with 20 years in delivering cutting edge technologies, we successfully helped clients around the world launch great tech products."
        />
      </Head>

      <BannerService />
      <BannerServiceMobile />
      <Counter projectDelivered={30} productLaunch={20} yearExp={15} />
      <WhoWeAre />
      <Specialized />
      <VisionCarousel />
      <CoreValue />
      <MeetTheTeam />
      <Life />
      <Partners />
      <ContactUs />
    </>
  );
};

export default Home;
