import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const Dedicated = ({ step }: { step: number }): JSX.Element => {
  return (
    <Wrap>
      <div className={step !== 3 ? 'hidden' : ''} id="banner-dedicated-desktop">
        <img src="images/home/banner/desktop.webp" />
      </div>
      <div className={step !== 3 ? 'hidden' : ''} id="banner-dedicated-woman">
        <img src="images/home/banner/woman-laptop.svg" />
        <svg
          className="dedicate-chat"
          width="47"
          height="53"
          viewBox="0 0 47 53"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.77473 34.9344L13.8263 37.2718L15.6372 46.1278C15.7107 46.473 15.8765 46.7917 16.1169 47.0502C16.3572 47.3086 16.6631 47.4971 17.0021 47.5955C17.341 47.6939 17.7003 47.6986 18.0417 47.609C18.3831 47.5194 18.6938 47.339 18.9408 47.0868L25.2123 40.5772L33.2638 42.9146C34.1237 43.1642 35.0475 43.062 35.8321 42.6305C36.6166 42.199 37.1976 41.4735 37.4472 40.6137L45.4274 13.1245C45.6774 12.2633 45.5762 11.338 45.1458 10.5512C44.7155 9.76431 43.991 9.17993 43.1309 8.92584L15.6418 0.945668C14.7778 0.694862 13.8496 0.797548 13.0614 1.2311C12.2731 1.66465 11.6894 2.39356 11.4386 3.25751L3.44504 30.7927C3.20911 31.6508 3.32151 32.5672 3.75779 33.3428C4.19406 34.1184 4.91893 34.6905 5.77473 34.9344Z"
            fill="#FBC02C"
          />
          <g opacity="0.9">
            <path
              opacity="0.9"
              d="M18.2587 14.084L33.8855 18.6205C34.3949 18.7684 34.9422 18.7079 35.407 18.4523C35.8717 18.1966 36.2159 17.7668 36.3638 17.2574C36.5117 16.748 36.4512 16.2007 36.1955 15.736C35.9399 15.2712 35.5101 14.927 35.0007 14.7791L19.3738 10.2426C18.8644 10.0947 18.3171 10.1553 17.8524 10.4109C17.3876 10.6665 17.0434 11.0963 16.8955 11.6057C16.7477 12.1151 16.8082 12.6624 17.0638 13.1272C17.3195 13.592 17.7493 13.9361 18.2587 14.084Z"
              fill="white"
            />
            <path
              opacity="0.9"
              d="M32.8684 22.1239L17.2415 17.5874C16.7321 17.4395 16.1848 17.5 15.7201 17.7557C15.2553 18.0113 14.9111 18.4411 14.7632 18.9505C14.6153 19.4599 14.6759 20.0072 14.9315 20.4719C15.1871 20.9367 15.6169 21.2809 16.1263 21.4288L31.7532 25.9653C32.2626 26.1132 32.8099 26.0526 33.2747 25.797C33.7394 25.5414 34.0836 25.1116 34.2315 24.6022C34.3794 24.0928 34.3188 23.5455 34.0632 23.0807C33.8076 22.616 33.3778 22.2718 32.8684 22.1239Z"
              fill="white"
            />
            <path
              opacity="0.9"
              d="M30.7766 29.3304L15.1497 24.7939C14.6403 24.646 14.093 24.7065 13.6283 24.9621C13.1635 25.2178 12.8193 25.6476 12.6714 26.157C12.5235 26.6664 12.5841 27.2136 12.8397 27.6784C13.0954 28.1432 13.5251 28.4874 14.0345 28.6352L29.6614 33.1718C30.1708 33.3197 30.7181 33.2591 31.1829 33.0035C31.6476 32.7478 31.9918 32.3181 32.1397 31.8087C32.2876 31.2993 32.227 30.752 31.9714 30.2872C31.7158 29.8224 31.286 29.4783 30.7766 29.3304Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
      <svg
        className={step !== 3 ? 'hidden' : 'dedicate-chat'}
        id="dedicate-chat-man"
        width="47"
        height="53"
        viewBox="0 0 47 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.84782 34.8701L13.8769 37.2838L15.6035 46.1566C15.6738 46.5025 15.8366 46.8228 16.0744 47.0835C16.3123 47.3442 16.6164 47.5356 16.9544 47.6372C17.2924 47.7388 17.6517 47.7469 17.9939 47.6605C18.3361 47.5742 18.6485 47.3967 18.8979 47.147L25.2309 40.6972L33.2599 43.1109C34.1174 43.3687 35.0421 43.2753 35.8307 42.8512C36.6193 42.4272 37.2071 41.7073 37.4649 40.8498L45.7057 13.4377C45.9639 12.5788 45.8715 11.6526 45.4486 10.8617C45.0258 10.0708 44.3069 9.47962 43.4492 9.21737L16.0371 0.976557C15.1756 0.717559 14.2465 0.811427 13.4541 1.23748C12.6618 1.66353 12.0712 2.38686 11.8122 3.24838L3.55756 30.7065C3.31349 31.5622 3.41719 32.4797 3.84608 33.2594C4.27497 34.0391 4.99437 34.618 5.84782 34.8701Z"
          fill="#D76FFC"
        />
        <g opacity="0.9">
          <path
            opacity="0.9"
            d="M18.5289 14.1392L34.112 18.8239C34.6199 18.9766 35.1678 18.9212 35.6349 18.67C36.1021 18.4188 36.4504 17.9923 36.6031 17.4843C36.7558 16.9764 36.7004 16.4285 36.4492 15.9614C36.198 15.4942 35.7715 15.1459 35.2635 14.9932L19.6805 10.3085C19.1725 10.1558 18.6247 10.2112 18.1575 10.4624C17.6903 10.7136 17.3421 11.1401 17.1893 11.648C17.0366 12.156 17.092 12.7039 17.3432 13.171C17.5944 13.6382 18.0209 13.9865 18.5289 14.1392Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M33.0619 22.3174L17.4788 17.6327C16.9708 17.48 16.423 17.5353 15.9558 17.7866C15.4886 18.0378 15.1404 18.4643 14.9877 18.9722C14.835 19.4802 14.8903 20.028 15.1415 20.4952C15.3927 20.9624 15.8192 21.3106 16.3272 21.4633L31.9103 26.148C32.4183 26.3007 32.9661 26.2454 33.4333 25.9942C33.9005 25.743 34.2487 25.3165 34.4014 24.8085C34.5541 24.3005 34.4988 23.7527 34.2476 23.2855C33.9964 22.8184 33.5699 22.4701 33.0619 22.3174Z"
            fill="white"
          />
          <path
            opacity="0.9"
            d="M30.9012 29.5037L15.3182 24.819C14.8102 24.6663 14.2623 24.7217 13.7952 24.9729C13.328 25.2241 12.9797 25.6506 12.827 26.1585C12.6743 26.6665 12.7297 27.2143 12.9809 27.6815C13.2321 28.1487 13.6586 28.4969 14.1666 28.6497L29.7497 33.3343C30.2576 33.4871 30.8055 33.4317 31.2726 33.1805C31.7398 32.9293 32.0881 32.5028 32.2408 31.9948C32.3935 31.4869 32.3381 30.939 32.0869 30.4719C31.8357 30.0047 31.4092 29.6564 30.9012 29.5037Z"
            fill="white"
          />
        </g>
      </svg>
    </Wrap>
  );
};

export default Dedicated;

const aniWoman = keyframes`
  0%{
    transform: translateX(-300px);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;
const aniDesktop = keyframes`
  0%{
    transform: translateX(-50%) scale(0);
    opacity: 0;
  }

  100%{
    transform: translateX(-50%) scale(1);
    opacity: 1;
  }
`;
const aniPop = keyframes`
  0% {
    transform: translate(-5px, -100%) scale(0);
  }
  75% {
    transform: translate(-5px, -100%) scale(1.1);
  }
  100% {
    transform: translate(-5px, -100%) scale(1);
  }
`;
const Wrap = styled.div`
  .dedicate-chat {
    transform: translate(-5px, -100%) scale(0);
    animation: ${aniPop} 0.5s ease 1.5s forwards;
  }

  #dedicate-chat-man {
    position: absolute;
    right: 12%;
    top: 55%;
  }

  #banner-dedicated-woman {
    position: absolute;
    bottom: 5px;
    left: 27%;
    transform: translateX(-300px);
    opacity: 0;
    animation: ${aniWoman} 1s ease forwards;

    svg {
      position: absolute;
      top: 0;
      left: 50%;
    }
  }

  #banner-dedicated-desktop {
    position: absolute;
    width: 79.84%;
    height: auto;
    top: 19%;
    left: 50%;
    transform: translateX(-50%) scale(0);
    opacity: 0;

    animation: ${aniDesktop} 0.5s ease forwards;

    img {
      width: 100%;
      height: auto;
    }
  }
`;
