import styled from '@emotion/styled';
import Link from 'next/link';
import Slider from 'react-slick';

import { BANNER_CONTENT } from 'constant/appConstant';
import { breakPoints } from 'constant/breakPoints';

export default function BannerServiceMobile() {
  return (
    <Wrap>
      <BlurWrap>
        <img src="/images/home/banner/mobile/blur.webp" />
      </BlurWrap>
      <Slider {...settings}>
        {BANNER_CONTENT.map((item, idx) => (
          <ItemWrap key={idx}>
            <Content key={0} className="banner-content animate-button">
              <p dangerouslySetInnerHTML={{ __html: item.subtitle }} />
              <p dangerouslySetInnerHTML={{ __html: item.title }} />
            </Content>
            <ImageWrap>
              <img src={images[idx]} />
            </ImageWrap>
            <Link href={item.link} passHref prefetch={false}>
              <ButtonReadMore>Read more</ButtonReadMore>
            </Link>
          </ItemWrap>
        ))}
      </Slider>
    </Wrap>
  );
}

const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  dots: true,
  arrows: false,
};

const images = [
  'images/home/banner/mobile/design.webp',
  'images/home/banner/mobile/web.webp',
  'images/home/banner/mobile/block.webp',
  'images/home/banner/mobile/dedicate.webp',
];

const Wrap = styled.div`
  display: none;
  position: relative;
  padding: 95px 16px 0;
  height: max-content;

  .slick-dots {
    bottom: -45px;

    button {
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      outline: 0;
      border-radius: 50%;

      &:before {
        display: none;
      }
    }

    .slick-active button {
      transform: scale(1.6);
      background-color: #fff;
    }
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    display: initial;
  }
`;

const ItemWrap = styled.div``;

const BlurWrap = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;

const ImageWrap = styled.div`
  width: 100%;

  img {
    margin: 2% auto;
    width: auto;
    height: 240px;
  }
`;

const Content = styled.div`
  padding-bottom: 22px;
  text-align: center;

  p {
    transition: all 1s ease;
    line-height: 130%;
    white-space: pre-wrap;
  }

  p:first-of-type {
    font-weight: 500;
    font-size: 16px;
    color: #d0cfd4;
    margin-bottom: 10px;
  }

  p:last-of-type {
    font-weight: 600;
    font-size: 32px;
    color: #ffffff;
    font-weight: 600;
  }

  a {
    position: absolute;
    bottom: 30.555vh;
    z-index: 20;
  }
`;

const ButtonReadMore = styled.a`
  margin: 40px auto 0;
  background: linear-gradient(
    188.34deg,
    #a336d9 13.45%,
    rgba(163, 54, 217, 0.6) 144.25%
  );
  border: 1px solid transparent;
  border-radius: 100px;
  width: 142px;
  height: 40px;
  display: block;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  color: #fff;
  font-size: 14px;

  &:hover {
    border-color: #fff;
    background: transparent;
    color: #fff;
  }
`;
