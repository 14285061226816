import { useEffect } from 'react';

export default function useTheme({ light = false }) {
  useEffect(() => {
    if (!process.browser) return;

    const root = document.documentElement;
    const body = document.querySelector('body');

    if (light) {
      body?.classList.add('light');
      root.style.setProperty('--purple-darken', '#fff');
      root.style.setProperty('--white', '#151027');
      root.style.setProperty('--gray2', '#73707D');
      root.style.setProperty('--color-background', 'rgba(255, 255, 255, 0.95)');
    } else {
      body?.classList.remove('light');
      root.style.setProperty('--purple-darken', '#151027');
      root.style.setProperty('--white', '#fff');
      root.style.setProperty('--gray2', '#d0cfd4');
      root.style.setProperty('--color-background', 'rgba(21, 16, 39, 0.95)');
    }
  }, [light]);
}
